import get from "lodash/get"
import type { Conditions, ConditionsContext } from "@/types/api/fields"

export function isConditionsMet (
  conditions: Conditions,
  model: Record<string, any>,
  context: ConditionsContext,
  check = "and",
): boolean {
  if (conditions.length === 0) return true

  const EMPTY_VALUES = [undefined, null, ""]
  const res = check === "and"

  for (const c of conditions) {
    const value = get(model, c.field)
    let r

    if (["and", "or", "not"].includes(c.check))
      r = isConditionsMet(c.value, model, context, c.check)
    else if (c.check === "equals") r = c.value === value
    else if (c.check === "contains") r = value?.includes(c.value) ?? false
    else if (c.check === "greater") r = Number(value ?? -Infinity) > Number(c.value)
    else if (c.check === "less") r = Number(value ?? Infinity) < Number(c.value)
    else if (c.check === "empty") r = EMPTY_VALUES.includes(value)
    else if (c.check === "isEdit") r = context.isEdit

    if (check === "not") r = !r
    if (r !== res) return r
  }

  return res
}
