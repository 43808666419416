// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ure0h9WU{padding-bottom:var(--spacing-md)}.ure0h9WU>:not(:first-child){padding-top:var(--spacing-xs)}.DM\\+\\+\\+GJp{word-wrap:break-word;font-size:1rem}.B0tMj90L{word-wrap:break-word;color:var(--color-gray-4);font-size:.75rem;line-height:1.2;padding-bottom:var(--spacing-xs);padding-top:var(--spacing-default)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"group": "ure0h9WU",
	"heading": "DM+++GJp",
	"description": "B0tMj90L"
};
module.exports = ___CSS_LOADER_EXPORT___;
